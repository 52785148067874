<template>
    <v-card
        elevation="2"
    >
        <v-card-title>Programa</v-card-title>
        <v-card-text>
            <v-row>
                <v-col>
                    <v-text-field
                            label="Nombre Programa"
                            placeholder="agrega un nombre para el programa"
                            v-model="About.data.attributes.name"
                            outlined
                        ></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                            label="Titulo del Programa"
                            placeholder="agrega un tittulo para el programa"
                            v-model="About.data.attributes.title"
                            outlined
                        ></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                            label="Subtitulo del Programa"
                            placeholder="agrega un subtittulo para el programa"
                            v-model="About.data.attributes.subtitle"
                            outlined
                        ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-text-field
                            label="Nombre Segmento"
                            placeholder="agrega un nombre para el segmento"
                            v-model="About.data.attributes.label_text"
                            outlined
                        ></v-text-field>
                </v-col>.
                <v-col> 
                    <v-file-input
                        accept="image/*"
                        label="Imagen de portada"
                        @change="onAboutImage"
                        outlined
                        dense
                    ></v-file-input>
                </v-col>
            </v-row>
            
            <h2>Texto informativo</h2>
            <br>
            <quill-editor
                ref="myQuillEditor"
                v-model="About.data.attributes.text"
                :options="editorOption"
            />
        </v-card-text>
        <v-card-actions>
            <v-btn
                elevation="2"
                small
                color="primary"
                @click="saveAbout"
            >
                Guardar
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import {misMixins} from '../../../vue/mixinsAlert'; 
  export default {
    data () {
      return {
        id:'',
        saveOrUpdate:false,
        About: {
            data:{
                attributes:{
                    name:'',
                    title:'',
                    subtitle:'',
                    label_text:'',
                    text: '',
                    imagen: null,
                    townhall_id: this.getClientSelect().id
                }
            }
            
        },
        editorOption: {
            
        }
      }
    },

    mixins:[misMixins],

    methods: {
        
        saveAbout(){
            this.LoadingOpen();
         
            if (this.saveOrUpdate == true){
                this.$http.post('programs', this.About,{headers:{"Authorization": 'Bearer '+this.getCrypto()}}).then(response => {
                    this.LoadingClose();

                        this.SucessAlert(
                        'Creado',
                        'programa creado con éxito'
                        )
                    console.log('Save about:', response)
                    this.getAbout();
                }).catch(error => {
                    console.log('Error al guardar About', error)
                    this.LoadingClose();
                    this.ErrorAlert('Error al crear el programa')
                })
            } else {
                this.$http.put('programs/'+this.id, this.About,{headers:{"Authorization": 'Bearer '+this.getCrypto()}}).then(response => {
                    this.LoadingClose();

                        this.SucessAlert(
                        'Actualizado',
                        'programa actualizado con éxito'
                        )
                    console.log('Save about:', response)
                    this.getAbout();
                }).catch(error => {
                    console.log('Error al guardar About', error)
                    this.LoadingClose();
                    this.ErrorAlert('Error al actualizar el programa')
                })
            }
            
        },
        getAbout(){
            this.$http.get('programs/townhall/'+this.getClientSelect().id).then(response => {
                this.About.data.attributes.name = response.data.name
                this.About.data.attributes.title = response.data.title
                this.About.data.attributes.subtitle = response.data.subtitle
                this.About.data.attributes.label_text = response.data.label_text
                this.About.data.attributes.text = response.data.text
                this.id=response.data.id
                console.log('Programas',response.data)
                this.saveOrUpdate=false
            }).catch(error => {
                this.ToastError(error.data.status +' '+error.data.error)
                this.saveOrUpdate=true
                console.log('Error al consultar About', error,this.saveOrUpdate)
                
            })
        },
        onAboutImage(imagen){
            var reader = new FileReader();
            var vm = this;

            reader.readAsDataURL(imagen);
            reader.onload = function () {
                vm.About.data.attributes.imagen = reader.result
            }
        },
    },
    created() {
        this.getAbout()
    },
  }
</script>